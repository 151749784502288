<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">重設密碼</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                重設密碼
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-8 bg-light">
      <div class="container">
        <div class="row flex-column justify-content-center align-items-center">
          <div class="col-xs-12 col-lg-4 mt-5" style="min-width: 400px">
            <div class="card bg-white card-hover mb-3">
              <div class="card-body text-center px-md-5 px-lg-6 my-2">
                <div class="card-icon-border-large border-light mtn-90 pt-6">
                  <i
                    class="fa fa-user text-light font-size-72"
                    aria-hidden="true"
                  ></i>
                </div>
                <blockquote class="blockquote blockquote-sm mt-2">
                  <footer
                    class="blockquote-footer text-uppercase font-size-32 mb-3"
                  >
                    <div>重設密碼</div>
                    <div class="text-danger" style="font-size: 16px;"><strong>密碼要六碼以上(包含英文、數字)</strong></div>
                  </footer>
                  <form action="#" method="POST" role="form">
                    <div class="input-group mb-5">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"
                          ><i class="fa fa-user" aria-hidden="true"></i
                        ></span>
                      </div>
                      <input
                        type="password"
                        class="form-control"
                        placeholder="請重新輸入新密碼"
                        aria-label="Email"
                        aria-describedby="basic-addon1"
                        v-model="password"
                      />
                    </div>
                    <div class="input-group mb-5">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"
                          ><i class="fa fa-user" aria-hidden="true"></i
                        ></span>
                      </div>
                      <input
                        type="password"
                        class="form-control"
                        placeholder="請確認新密碼"
                        aria-label="Email"
                        aria-describedby="basic-addon1"
                        v-model="checkPassword"
                      />
                    </div>

                    <div class="form-group">
                      <button
                        type="button"
                        class="btn btn-danger text-uppercase w-100"
                        @click="updatePassword"
                      >
                        確認送出
                      </button>
                    </div>
                  </form>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
export default {
  name: 'reset',
  data () {
    return {
      retoken: '',
      password: '',
      checkPassword: ''
    }
  },
  created () {
    const vm = this
    const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/RemakePassword`

    vm.axios.post(api, {
      token: tokenGenerator(),
      retoken: vm.$route.query.toke
    })
      .then((res) => {
        if (res.data.status !== '000') {
          alert(res.data.message)
          vm.$router.push('/forgot')
        } else {
          vm.retoken = res.data.retoken
        }
      })
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  methods: {
    updatePassword () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/Passwordupdate`

      if (vm.password.length < 6 || vm.checkPassword.length < 6) {
        alert('修改失敗，密碼至少要六碼以上(包含英文、數字)')
        return false
      }

      if (vm.password !== vm.checkPassword) {
        alert('密碼不一致')
        return false
      }

      vm.axios.post(api, {
        token: tokenGenerator(),
        user_pwd: vm.password,
        retoken: vm.retoken
      })
        .then((res) => {
          console.log(res)
          if (res.data.status !== '000') {
            alert(`修改失敗, ${res.data.message}`)
          } else {
            alert('修改成功')
            location.href = '/'
          }
        })
    }
  }
}
</script>
